<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTs 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Increasing production overall <br>solution technical service
              </div>
              <div class="title2">
                Joint innovation with customers, close integration of geology and engineering, supporting a stable team of geological engineering integration experts, with the purpose of increasing production, breaking engineering boundaries, unified dispatching and command, and providing customers with integrated overall solutions.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="pro2">
              <!--        油藏-->
              <div class="grand2" >
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/groundincease1.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                  </div>
                  <div>
                    <div class="title1">
                      Technical features
                    </div>
                    <div class="title2">
                      With production as the goal, on the basis of the understanding of the main controlling factors of oil and gas reservoir geology and productivity, and drawing on the latest technological achievements at home and abroad and the previous construction experience, targeted technical measures and combinations are proposed.  </div>
                  </div>

                </div>
              </div>
              <!--        智能-->
              <div class="grand2">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/groundincease2.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                  </div>
                  <div >
                    <div class="title1">
                      Application scenarios


                    </div>
                    <div class="title2">
                      Suitable for efficient development of unconventional oil and gas reservoirs such as tight oil and gas, shale oil and gas, and coalbed methane.
                    </div>
                  </div>
                </div>
              </div>
              <!--        智能-->
              <div class="grand2">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/groundincease3.png"
                         style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                  </div>
                  <div>
                    <div class="title1">
                      Innovation mode

                    </div>
                    <div class="title2">
                      Jointly innovation with customers, innovative thinking and concepts, share preliminary understanding, share service resources, share talent teams, share research results.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">
              Successful Cases
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="../../assets/groundincrease4.png">
                  </div>
                  <div class="titleDesign">
                    <div>
                      <div class="title2">
                        <li>In 2020, we undertook technical general contracting for the Changning H21-X shale gas project in southwest China and drilled PetroChina's "largest deviated depth shale gas ultra-long horizontal well" with a design period of 60 days and an actual drilling completion period of 42 days, shortening the drilling period by 30%. The cost reduction effect is significant. </li>
                        <li>In 2022, under the risk general contracting of a certain platform in Daniudi, Sinopec North China Bureau Gas Production Plant No. 1, the average daily output of three wells was 36,000 cubic meters, an increase of 44% year-on-year in the same block. Among them, the test output of one well was 198,000 cubic meters per day. The output reached a record high in Daniu land.  </li>
                        <li>In 2023, XF11XX and XF16XX geological engineering integrated general contracting services were provided in the Fuxian work area of inopec North China Bureau Oil Production Plant No. 1. Well XF11XX set 7 project records and set the highest production record in the Fuxian area at that time; well XF16XX set the highest output record for vertical wells in the Fuxian area.  </li>
                        <li>In 2024, under the integrated general contracting service of geology and engineering of the Tachang XX5 well in the Dadachang work area of the Southwest Oil and Gas Field Development Division, the rolling evaluation of the SXM group gas reservoir obtained high-yield industrial gas flow, setting a new record for block test output.</li>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip><netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div></div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTsGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Increasing production overall <br>solution technical service
              </div>
              <div class="title2">
                Joint innovation with customers, close integration of geology and engineering, supporting a stable team of geological engineering integration experts, with the purpose of increasing production, breaking engineering boundaries, unified dispatching and command, and providing customers with integrated overall solutions.</div>    </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="grid">
              <!--        油藏-->
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundincease1.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="titleDesign">
                  <div class="title1">
                    Technical features
                  </div>
                  <div class="title2">
                    With production as the goal, on the basis of the understanding of the main controlling factors of oil and gas reservoir geology and productivity, and drawing on the latest technological achievements at home and abroad and the previous construction experience, targeted technical measures and combinations are proposed.

                </div>
                </div>

              </div>
              <!--        智能-->
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundincease2.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="titleDesign">
                  <div class="title1">
                    Application scenarios


                  </div>
                  <div class="title2">
                    Suitable for efficient development of unconventional oil and gas reservoirs such as tight oil and gas, shale oil and gas, and coalbed methane.
                  </div>
                </div>
              </div>
              <!--        智能-->
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/groundincease3.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="titleDesign">
                  <div class="title1">
                    Innovation mode

                  </div>
                  <div class="title2">
                    Jointly innovation with customers, innovative thinking and concepts, share preliminary understanding, share service resources, share talent teams, share research results.
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="screenmake1">
            <div class="heighLight">
              Successful Cases</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="../../assets/groundincrease4.png">
                  </div>
                  <div class="titleDesign">
                    <div>
                      <div class="title2">
                        <li>In 2020, we undertook technical general contracting for the Changning H21-X shale gas project in southwest China and drilled PetroChina's "largest deviated depth shale gas ultra-long horizontal well" with a design period of 60 days and an actual drilling completion period of 42 days, shortening the drilling period by 30%. The cost reduction effect is significant. </li>
                        <li>In 2022, under the risk general contracting of a certain platform in Daniudi, Sinopec North China Bureau Gas Production Plant No. 1, the average daily output of three wells was 36,000 cubic meters, an increase of 44% year-on-year in the same block. Among them, the test output of one well was 198,000 cubic meters per day. The output reached a record high in Daniu land.  </li>
                        <li>In 2023, XF11XX and XF16XX geological engineering integrated general contracting services were provided in the Fuxian work area of inopec North China Bureau Oil Production Plant No. 1. Well XF11XX set 7 project records and set the highest production record in the Fuxian area at that time; well XF16XX set the highest output record for vertical wells in the Fuxian area.  </li>
                        <li>In 2024, under the integrated general contracting service of geology and engineering of the Tachang XX5 well in the Dadachang work area of the Southwest Oil and Gas Field Development Division, the rolling evaluation of the SXM group gas reservoir obtained high-yield industrial gas flow, setting a new record for block test output.</li>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>

</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, resultTip, headtip,bottomTip},

  data() {

    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      pageTitle: 'Increasing production overall solution technical service -Geological Engineering GPTs',
      pageDescription: ' Anton is committed to joint innovation with customers, through the close integration of geological engineering, precision engineering technology, lean operation management, to provide customers with integrated overall solutions, relying on mature project management experience, advanced and applicable technology combination, from price competition to value creation, through innovation, for oil and gas field customers to create breakthrough production and efficiency contribution. ',
      pageKeywords: 'Increasing production overall solution technical service ,Geological Engineering GPTs',
      model:false,
      dialogconsult:false,
      dialogVisible:false,

    }

  },

  methods: {
    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  },

  mounted() {

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '增产整体解决方案-Geological Engineering GPTs',
        pageDescription: '安东致力于与客户联合创新，通过地质工程紧密结合，精准工程技术，精益作业管理，为客户提供一体化整体解决方案 ，依靠成熟的项目管理经验，先进适用的技术组合，从价格竞争上升到价值创造，通过创新，为油气田客户创造突破性增产提效贡献。',
        pageKeywords: '增产整体解决方案,Geological Engineering GPTs',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 0 0 0 0;
      background-image: url("../../assets/groundIncrease.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }

        .title2 {
          margin-top: 3vh;
          width: 40vw;
          height: fit-content;

          font-size: 1.0597920277vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: black;
          line-height: 4vh;
        }
        .titlle3{
          margin-top: 3vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


    }
    .screenmake0{
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .pro2 {
        margin-top:2.78739184177998vh ;
        border-radius: 9px;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .grand2 {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          min-height: 30.65636588380717vh;
          padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
          opacity: 1;
          position: relative;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            height: 100%;
            background: #D4E4FF;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }
          .photo {
            border-radius: 9px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }
          .title1 {
            opacity: 4;
            margin: 3.5vh auto 0 auto;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.71940667490729vh;
            font-size: 1.17094165222415vw;
            font-family: Roboto,sans-serif;
            font-weight: bold;
            color: black;
            line-height: 2.71940667490729vh;
          }
          .title2 {
            opacity: 4;
            margin: 1.94437577255871vh 1vw 2.94437577255871vh 1vw;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.80878105141537vw;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }


        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }
        }
      }

    }
    .screenmake1{
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        color: white;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: white;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #19202e;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:45vw;
              height:70vh;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5vh 2.5248454883vw 5vh 2.5248454883vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: white;
              width: 55%;

              .title2{

                line-height: 3.194066749vh;
                font-size:  0.80878105141537vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 530px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../assets/groundrisk1banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;

        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;

        .back{
          margin-top:25px;
          background-color: #cad6ef;
          border: white 1px solid;
          box-shadow: 2px 0 12px 0px #c9d9f5;
          border-radius: 9px;
          position: relative;
          height:fit-content;
          overflow:hidden;

          .imgDesign{
            text-align: center;
            height:fit-content;
            width: 100%;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 5px 5px ;
            .title1{
              text-align: center;
              height: fit-content;
              font-size:16px;
              font-family: Roboto,sans-serif;
              font-weight: bold;
              line-height: 32px;
            }
            .title2{
              margin: 0px 8px 5px 8px;

              height: fit-content;
              font-size:14px;
              font-family: Roboto,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
          }


        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;

        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin:25px 0 10px 0;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0 10px;
          opacity: 1;
          .back{
            padding: 0 0 5px 0;
            background-color: white;
            border-radius: 9px;
            position: relative;
            height:fit-content ;
            overflow:hidden;

            .imgDesign{
              text-align: center;
              height:fit-content;
              width: 100%;

            }
            .titleDesign{
              padding: 10px 5px 0 5px;
              .title1{
                margin:5px 10px ;
                height: fit-content;
                font-size: 16px;
                font-family: Roboto,sans-serif;
                font-weight: bold;
                line-height:22px;
              }
              .title2{
                margin: 0px 8px 5px 8px;

                height: fit-content;
                font-size:14px;
                font-family: Roboto,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

  }

}
</style>
