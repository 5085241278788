<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology
              </div>
              <div class="title2" style="text-align: left">The application of full-scale fracture-controlled fracturing technology in
                unconventional oil and gas reservoirs (shale, sandstone, deep coalbed methane, etc.) is composed of two
                design concepts: multi-scale fracture generation and full-scale fracture filling. By improving the SRV
                and effective fracture conductivity after the reconstruction, the initial post-frac production is
                increased and the stable production period is extended, and the optimal cumulative production is finally
                achieved.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenmake0">
            <div class="heighLight">Technical Principle
            </div>
            <div class="heightLight2">Increase initial production and prolong stable production period by increase SRV
              and effective fracture conductivity, eventually achieve optimized cumulative production
            </div>
<!--            <div style="width: 100%;height: fit-content;text-align: center;">-->
<!--              <img style="border-radius: 9px;width: 80%;" loading="lazy" src="@/assets/subordinate/fracture1.png" />-->
<!--            </div>-->
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Core Technology
            </div>
            <div class="lr-box" style="">
              <div style="height: fit-content;width: 60%;margin: auto">
                <img style="border-radius: 9px;height: 100%; margin-top: 4vh;margin-bottom: 2vh;" loading="lazy"
                  src="@/assets/fullscaleT1.png" />
              </div>
              <div class="content_text" style="padding: 0vh 2vw">
                <h4>① Fine Geological Research + Geological Engineering Integrated Fracturing Design:</h4>
                <p>By establishing 3D fine comprehensive model of properties, natural fractures and ground stresses, the
                  basis for fine design is provided.</p>
                <p>Through the combination of geology and engineering, precise three-dimensional geological and
                  geo-mechanical modeling, accurate understanding of sand body distribution to carry out personalized
                  and fine joint design; The reservoir filtration capacity matches the fracture conductivity capacity,
                  and the operation scale and parameters are precisely designed to realize the fine fracturing design of
                  "one stage and one policy".</p>
              </div>
            </div>
          </div>

          <div class="screenmake0" style="text-align: left">
            <div class="lr-box rl">
              <div style="height: fit-content;width: 100%">
                <img style="border-radius: 9px;width: 100%; margin-top: 4vh;margin-bottom: 2vh;object-fit: fill" loading="lazy"
                     src="@/assets/fullscaleT2.png" />
              </div>
              <div class="content_text">
                <h4>② Special Polymer Dry Powder On-Fly Viscosity Adjustable Fracturing Fluid + Nano waterproof lock
                  functional liquid technology system:</h4>
                <p>The equipment is transported by skid truck, which is composed of liquid loading system, powder
                  transporting and mixing system, pumping system and control system.</p>
                <p>The nano fluid can effectively change rock wettability while reducing adsorption, increase contact
                  angle, nano dispersing agent (adjustable scale, improve the spread range) and high efficiency
                  regulator (improve compatibility and can be specifically designed with adhesion stability, foaming,
                  asphalt/paraffin solvent and inhibitor effects) composition.</p>
              </div>
<!--              <div style="height: fit-content">-->
<!--                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture3.png" />-->
<!--              </div>-->
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box" style="display: block">
              <div style="height: fit-content;width: 70%;margin: auto">
                <img style="border-radius: 9px;height: 100%; margin-top: 4vh;margin-bottom: 2vh;" loading="lazy"
                     src="@/assets/fullscaleT3.jpg" />
              </div>
              <div class="content_text">
                <h4>③ Multi particle size proppant + self-suspension proppant + consolidated sand anti-backflow
                  technology system:</h4>
                <p>The multi-particle size proppant combination is preferred, and the lower grade of proppant is more
                  conducive to obtaining long fracture length and uniform placement profile, which is conducive to
                  realizing full-scale fracture support.</p>
                <p>The self-suspension proppant, which is water-soluble coated and dispersed into the liquid, can
                  improve the sand profile, reduce the operation pressure, improve the fracture filling effect, achieve
                  full-scale fracture support, and ultimately increase the production rate of a single well.</p>
                <p>Flexible consolidated sand is a kind of fracturing proppant product, which is made of secondary
                  proppant coating through certain physical and chemical methods, and is loose and granular at normal
                  temperature. It has typical characteristics of self-bonding under certain conditions, and is permeable
                  to oil, air and water. By following a part of flexible consolidated sand, it can prevent formation
                  sand production and prevent proppant flowback.</p>
              </div>
            </div>
          </div>

          <div class="screenmake0">
            <div class="lr-box rl">
              <div style="height: fit-content;width: 100%">
                <img style="border-radius: 9px;width: 100%; margin-top: 4vh;margin-bottom: 2vh;object-fit: fill" loading="lazy"
                     src="@/assets/fullscaleT4.png" />
              </div>
              <div class="content_text">
                <h4>④ Real-time iterative fracturing technology + visual monitoring technology:</h4>
                <p>Integrated real-time iterative analysis of geological engineering (real-time diagnosis of fracturing
                  curve, iteration of geological model update, etc.) to realize real-time assessment of fracture
                  propagation and geological re-understanding.</p>
                <p>Combined with electro-magnetic monitoring, the optimization and adjustment of the scheme are carried
                  out: alternating current is supplied to the wellbore, and the wellbore and fracturing fluid form an
                  integrated underground conductor. The measuring points are deployed on the surface, electromagnetic
                  response caused by the electrical change after fracturing fluid is injected into the ground will be
                  measured, reflecting the spread range of fracturing fluid, and then the fracture network
                  characteristics are analyzed. Compared with the current mainstream surface micro-seismic monitoring,
                  it is closer to the morphology of effectively connected cracks.</p>
              </div>
<!--              <div style="height: fit-content">-->
<!--                <img style="border-radius: 9px;width: 100%; height: 51vh;" loading="lazy"-->
<!--                  src="@/assets/subordinate/fracture5.png" />-->
<!--              </div>-->
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box">
<!--              <div style="height: fit-content">-->
<!--                <img-->
<!--                  style="border-radius: 9px;width: 100%;height: 27vh;margin-top: 5vh; object-fit: contain;background-color: #fff;"-->
<!--                  loading="lazy" src="@/assets/subordinate/fracture6.png" />-->
<!--              </div>-->
              <div style="height: fit-content;width: 100%">
                <img style="border-radius: 9px;width: 100%; margin-top: 4vh;margin-bottom: 2vh;object-fit: fill" loading="lazy"
                     src="@/assets/fullscaleT5.png" />
              </div>
              <div class="content_text">
                <h4>⑤Diverting Technology:</h4>
                <p>Soluble temporary plugging knot is a synthetic polymer with knot shape, inject soluble temporary
                  plugging knot during fracturing (acid fracturing) process, effectively seal perforations and near
                  wellbore fracture to divert fluid to initiate new fracture, improve homogeneous stimulation result of
                  different properties, formation stress and clusters.</p>
              </div>
            </div>
          </div>

            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
             <netFeedback  :dialogVisible="dialogNet"
                      @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology</div>
              <div class="title2">The application of full-scale fracture-controlled fracturing technology in
                unconventional oil and gas reservoirs (shale, sandstone, deep coalbed methane, etc.) is composed of two
                design concepts: multi-scale fracture generation and full-scale fracture filling. By improving the SRV
                and effective fracture conductivity after the reconstruction, the initial post-frac production is
                increased and the stable production period is extended, and the optimal cumulative production is finally
                achieved.</div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--Technical Principle  -->
          <div class="screenmake0">
            <div class="heighLight">Technical Principle
            </div>
            <div class="heightLight2">Increase initial production and prolong stable production period by increase SRV
              and effective fracture conductivity, eventually achieve optimized cumulative production
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture1.png" />

            </div>
          </div>
          <!-- Core Technology -->
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Core Technology
            </div>
            <div class="heightLight2">
              <h4>①Fine Geological Research + Geological Engineering Integrated Fracturing Design:</h4>
              <p>By establishing 3D fine comprehensive model of properties, natural fractures and ground stresses, the
                basis for fine design is provided.</p>
              <p>Through the combination of geology and engineering, precise three-dimensional geological and
                geo-mechanical modeling, accurate understanding of sand body distribution to carry out personalized
                and fine joint design; The reservoir filtration capacity matches the fracture conductivity capacity,
                and the operation scale and parameters are precisely designed to realize the fine fracturing design of
                "one stage and one policy".</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture2.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              <h4>②Special Polymer Dry Powder On-Fly Viscosity Adjustable Fracturing Fluid + Nano waterproof lock
                functional liquid technology system:</h4>
              <p>The equipment is transported by skid truck, which is composed of liquid loading system, powder
                transporting and mixing system, pumping system and control system.</p>
              <p>The nano fluid can effectively change rock wettability while reducing adsorption, increase contact
                angle, nano dispersing agent (adjustable scale, improve the spread range) and high efficiency
                regulator (improve compatibility and can be specifically designed with adhesion stability, foaming,
                asphalt/paraffin solvent and inhibitor effects) composition.</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture3.png" />
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heightLight2">
              <h4>③Multi particle size proppant + self-suspension proppant + consolidated sand anti-backflow
                technology system:</h4>
              <p>The multi-particle size proppant combination is preferred, and the lower grade of proppant is more
                conducive to obtaining long fracture length and uniform placement profile, which is conducive to
                realizing full-scale fracture support.</p>
              <p>The self-suspension proppant, which is water-soluble coated and dispersed into the liquid, can
                improve the sand profile, reduce the operation pressure, improve the fracture filling effect, achieve
                full-scale fracture support, and ultimately increase the production rate of a single well.</p>
              <p>Flexible consolidated sand is a kind of fracturing proppant product, which is made of secondary
                proppant coating through certain physical and chemical methods, and is loose and granular at normal
                temperature. It has typical characteristics of self-bonding under certain conditions, and is permeable
                to oil, air and water. By following a part of flexible consolidated sand, it can prevent formation
                sand production and prevent proppant flowback.</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px" loading="lazy" src="@/assets/subordinate/fracture4.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              <h4>④Real-time iterative fracturing technology + visual monitoring technology:</h4>
              <p>Integrated real-time iterative analysis of geological engineering (real-time diagnosis of fracturing
                curve, iteration of geological model update, etc.) to realize real-time assessment of fracture
                propagation and geological re-understanding.</p>
              <p>Combined with electro-magnetic monitoring, the optimization and adjustment of the scheme are carried
                out: alternating current is supplied to the wellbore, and the wellbore and fracturing fluid form an
                integrated underground conductor. The measuring points are deployed on the surface, electromagnetic
                response caused by the electrical change after fracturing fluid is injected into the ground will be
                measured, reflecting the spread range of fracturing fluid, and then the fracture network
                characteristics are analyzed. Compared with the current mainstream surface micro-seismic monitoring,
                it is closer to the morphology of effectively connected cracks.</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture5.png" />
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heightLight2">
              <h4>⑤Diverting Technology:</h4>
              <p>Soluble temporary plugging knot is a synthetic polymer with knot shape, inject soluble temporary
                plugging knot during fracturing (acid fracturing) process, effectively seal perforations and near
                wellbore fracture to divert fluid to initiate new fracture, improve homogeneous stimulation result of
                different properties, formation stress and clusters.</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture6.png" />
            </div>
          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback, PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: '',
      pageTitle: 'Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology',
      pageDescription: 'The application of full-scale fracture-controlled fracturing technology in unconventional oil and gas reservoirs (shale, sandstone, deep coalbed methane, etc.) is composed of two design concepts: multi-scale fracture generation and full-scale fracture filling. By improving the SRV and effective fracture conductivity after the reconstruction, the initial post-frac production is increased and the stable production period is extended, and the optimal cumulative production is finally achieved.',
      pageKeywords: 'Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology,Geological Engineering GPTs',
      // model: false,
      screenWidth: window.innerWidth, // 初始屏幕宽度
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/tube2.png"),

        title3: "" +
          "<li>2007年第一口井现场试验成功，截止2021年4月底，已经在胜利、中原、华北、普光气田、南阳、江苏、克拉玛依、大港、青海等10个油田整形916口井，成功763口井，成功率83.3%；近三年现场施工成功率达95.1%。</li>"
      },
      ]
    }

  },

  computed: {
    model() {
      return this.screenWidth <= 700;
    },
  },
      methods: {hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    // 更新 `screenWidth` 的值
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;

    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    this.updateScreenWidth();
    // 添加窗口大小变化监听器
    window.addEventListener('resize', this.updateScreenWidth);

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding:0 0 0 0;
      background-image: url("../../../assets/subordinate/special1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */


      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;
        width: 45%;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns: minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Roboto,sans-serif;
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 68vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 100%;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;

              .title1 {
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }

              .title2 {
                line-height: 3.5vh;
                font-size: 0.9vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 70vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }

    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .content_text{
        text-align: left;
      }
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Roboto,sans-serif;
        text-align: left;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 550px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/special1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 21px;

        h4 {
          margin-bottom: 3px;
        }

        p {
          margin-bottom: 2px;
          line-height: 21px;
        }
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          //cursor: pointer;
          width: 98%;
          margin: 0 auto;
          min-height: 250px;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 214px;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 340px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}

.lr-box {
  display: flex;
  align-items: center;
  // margin-top: 3vh;
  text-align: justify;

  h4 {
    font-size: 1.2vw;
    margin-bottom: 3vh;
  }

  p {
    margin-bottom: .5vh;
    font-size: 1vw;
    line-height: 2;
  }

  &>div {
    flex: 1;
  }

  &>div:first-child {
    width: 50%;
    margin-right: 3vw;
  }
}
</style>
