import { render, staticRenderFns } from "./specialpolymer.vue?vue&type=template&id=59a6c496&scoped=true"
import script from "./specialpolymer.vue?vue&type=script&lang=js"
export * from "./specialpolymer.vue?vue&type=script&lang=js"
import style0 from "./specialpolymer.vue?vue&type=style&index=0&id=59a6c496&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a6c496",
  null
  
)

export default component.exports