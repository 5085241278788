<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Anton Integrated Viscous Acid Technology
              </div>
              <div class="title2">In view of the requirements of acid fluid for the harsh and complex engineering
                geological characteristics of deep and ultra deep carbonate reservoirs, a lotion thickener was
                developed, which has the characteristics of "multi fluid integration", "three-dimensional viscosity
                change", meets the requirements of online mixing, and adapts to the needs of different reservoir types.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenGpts" style="background-color: #fff;" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Technical characteristics
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 2vh 0.5vw;width: 60%">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px;height: 50vh;"
                      src="@/assets/subordinate/viscousacid1.png" />
                  </div>

                  <div class="titleDesign">
                    <div class="title1">3D variable viscosity </div>
                    <div class="title2">
                      <li>Time viscosity change: By changing the molecular structure design, the complete stretching
                        time of molecular chains in hydrochloric acid can be adjusted for 2 to 8 minutes, depending on
                        the well depth and design displacement.</li>
                      <li>Temperature viscosity change: The viscosity change acid system has good viscosity change
                        performance, and the viscosity of the acid solution in the wellbore gradually increases with
                        increasing depth and temperature. </li>
                      <li>Acid type viscosity change: change the acid type, integrate high-temperature viscosity change,
                        adjustable acid viscosity from 3 to 255 mPa·s, wide range viscosity change. </li>
                    </div>
                    <div class="title1">Multi liquid integrated - ready to dispense and inject </div>
                    <div class="title2">
                      <li>On site online configuration and injection usage mode, achieving "multi liquid integration";
                      </li>
                      <li>Real time switching based on acid fracturing process, achieving safe, efficient, and
                        controllable acid fracturing process </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Performance advantages
            </div>
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%; margin-top: 4vh;" loading="lazy"
                  src="@/assets/subordinate/viscousacid3.png" />
              </div>
              <div>
                <h4>① Strong thermal stability performance </h4>
                <p>Under the condition of 180 ℃, heating for 4 hours, and no layering, flocculation, precipitation or
                  other phenomena after removal, it indicates that the thermal stability of the acid solution system is
                  good. </p>
              </div>
            </div>
          </div>

          <div class="screenmake0">
            <div class="lr-box rl">
              <div>
                <h4>② Anti shear and high temperature resistance </h4>
                <p>Under the condition of 160 ℃, the viscosity of acid solution systems with different concentrations
                  remained above 65% after being sheared for 1.5 hours under simulated wellbore (1350s<sup>-1</sup>) and
                  formation (100s<sup>-1</sup>) conditions.</p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy"
                  src="@/assets/subordinate/viscousacid4.png" />
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: fit-content; margin-top: 6vh;" loading="lazy"
                  src="@/assets/subordinate/viscousacid5.png" />
              </div>
              <div>
                <h4>③ Thoroughly break the adhesive, with low viscosity </h4>
                <p>Under the condition of 140 ℃, the gel can be completely broken after 4 hours, with a viscosity of
                  6-9mPa · s. </p>
              </div>
            </div>
          </div>

          <div class="screenmake0">
            <div class="lr-box rl">
              <div>
                <h4>④ Superior deceleration performance, achieving remote etching</h4>
                <p>The dynamic corrosion rate of N80 steel sheets in an integrated three-dimensional variable viscosity
                  acid system at 180 ℃ is 31.6 g/(㎡·h), which meets the requirements of industry indicators (≤ 80
                  g/(㎡·h)). The surface corrosion of the steel sheets is uniform and without pitting corrosion </p>
                <p>Under the condition of 160 ℃, conduct acid etching experiments with alternating injection of
                  conventional gelling acid and variable viscosity acid, and image them using a laser scanning device;
                </p>
                <p>Due to the shorter rock plate and higher rate of integrated three-dimensional variable viscosity acid
                  inhibition, alternating injection did not exhibit significant non-uniform etching caused by viscous
                  fingering. The acid inhibition rate indicates that the integrated three-dimensional variable viscosity
                  acid has better performance and may penetrate farther than conventional cementitious acid. </p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%; height: 51vh;" loading="lazy"
                  src="@/assets/subordinate/viscousacid6.png" />
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box">
              <div style="height: fit-content">
                <img
                  style="border-radius: 9px;width: 100%;height: 27vh;margin-top: 5vh; object-fit: contain;background-color: #fff;"
                  loading="lazy" src="@/assets/subordinate/viscousacid7.png" />
              </div>
              <div>
                <h4>⑤ Deep penetration </h4>
                <p>Under the same closing pressure conditions, the integrated high-temperature variable viscosity acid
                  solution has a higher flow conductivity, and as the acid etching distance increases, the flow
                  conductivity at the far end of the crack can be maintained well</p>
                <p>Compared to other acid solutions, integrated high-temperature variable viscosity acid solutions have
                  a larger etching volume at the far end, rough etching surfaces, greater depth, and uneven sheet-like
                  distribution of etching surfaces, resulting in better conductivity </p>
              </div>
            </div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">Application performance
            </div>
            <div class="heightLight2">Since its development and finalization, the integrated variable viscosity acid
              product has been applied in various types of reservoir renovation wells. The following are the application
              situations of several wells.
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 80%;" loading="lazy" src="@/assets/subordinate/viscousacid8.png" />

            </div>
          </div>
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
             <netFeedback  :dialogVisible="dialogNet"
                      @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Anton Integrated Viscous Acid Technology </div>
              <div class="title2">In view of the requirements of acid fluid for the harsh and complex engineering
                geological characteristics of deep and ultra deep carbonate reservoirs, a lotion thickener was
                developed, which has the characteristics of "multi fluid integration", "three-dimensional viscosity
                change", meets the requirements of online mixing, and adapts to the needs of different reservoir types.
              </div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--Technical Principle  -->
          <div class="screenmake0">
            <div class="heighLight">Technical characteristics
            </div>
            <div class="heightLight2">
              <h4>3D variable viscosity </h4>
              <li>Time viscosity change: By changing the molecular structure design, the complete stretching time of
                molecular chains in hydrochloric acid can be adjusted for 2 to 8 minutes, depending on the well depth
                and design displacement.</li>
              <li>Temperature viscosity change: The viscosity change acid system has good viscosity change performance,
                and the viscosity of the acid solution in the wellbore gradually increases with increasing depth and
                temperature. </li>
              <li>Acid type viscosity change: change the acid type, integrate high-temperature viscosity change,
                adjustable acid viscosity from 3 to 255 mPa·s, wide range viscosity change. </li>
              <h4>Multi liquid integrated - ready to dispense and inject </h4>
              <li>On site online configuration and injection usage mode, achieving "multi liquid integration";
                      </li>
                      <li>Real time switching based on acid fracturing process, achieving safe, efficient, and
                        controllable acid fracturing process </li>
              </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid1.png" />

            </div>
          </div>
          <!--Performance advantages  -->
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">Performance advantages
            </div>
            <div class="heightLight2">
              <h4>① Strong thermal stability performance</h4>
              <p>Under the condition of 180 ℃, heating for 4 hours, and no layering, flocculation, precipitation or
                  other phenomena after removal, it indicates that the thermal stability of the acid solution system is
                  good. </p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/fracture2.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              <h4>② Anti shear and high temperature resistance </h4>
              <p>Under the condition of 160 ℃, the viscosity of acid solution systems with different concentrations
                  remained above 65% after being sheared for 1.5 hours under simulated wellbore (1350s<sup>-1</sup>) and
                  formation (100s<sup>-1</sup>) conditions.</p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid4.png" />
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heightLight2">
              <h4>③ Thoroughly break the adhesive, with low viscosity  </h4>
              <p>Under the condition of 140 ℃, the gel can be completely broken after 4 hours, with a viscosity of
                6-9mPa · s. </p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid5.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              <h4>④ Superior deceleration performance, achieving remote etching</h4>
                <p>The dynamic corrosion rate of N80 steel sheets in an integrated three-dimensional variable viscosity
                  acid system at 180 ℃ is 31.6 g/(㎡·h), which meets the requirements of industry indicators (≤ 80
                  g/(㎡·h)). The surface corrosion of the steel sheets is uniform and without pitting corrosion </p>
                <p>Under the condition of 160 ℃, conduct acid etching experiments with alternating injection of
                  conventional gelling acid and variable viscosity acid, and image them using a laser scanning device;
                </p>
                <p>Due to the shorter rock plate and higher rate of integrated three-dimensional variable viscosity acid
                  inhibition, alternating injection did not exhibit significant non-uniform etching caused by viscous
                  fingering. The acid inhibition rate indicates that the integrated three-dimensional variable viscosity
                  acid has better performance and may penetrate farther than conventional cementitious acid. </p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid6.png" />
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heightLight2">
              <h4>⑤ Deep penetration  </h4>
              <p>Under the same closing pressure conditions, the integrated high-temperature variable viscosity acid
                  solution has a higher flow conductivity, and as the acid etching distance increases, the flow
                  conductivity at the far end of the crack can be maintained well</p>
                <p>Compared to other acid solutions, integrated high-temperature variable viscosity acid solutions have
                  a larger etching volume at the far end, rough etching surfaces, greater depth, and uneven sheet-like
                  distribution of etching surfaces, resulting in better conductivity </p>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid7.png" />
            </div>
          </div>
          <!-- Application performance -->
          <div class="screenmake0">
            <div class="heighLight">Application performance
            </div>
            <div class="heightLight2">
              Since its development and finalization, the integrated variable viscosity acid
              product has been applied in various types of reservoir renovation wells. The following are the application
              situations of several wells.
              </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/viscousacid8.png" />

            </div>
          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback, PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: '',
      pageTitle: 'Anton Integrated Viscous Acid Technology ',
      pageDescription: 'In view of the requirements of acid fluid for the harsh and complex engineering geological characteristics of deep and ultra deep carbonate reservoirs, a lotion thickener was developed, which has the characteristics of "multi fluid integration", "three-dimensional viscosity change", meets the requirements of online mixing, and adapts to the needs of different reservoir types. ',
      pageKeywords: 'Anton Integrated Viscous Acid Technology ,Geological Engineering GPTs',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/subordinate/viscousacid2.png"),

        title3: "" +
          "<li>在西南深层碳酸盐岩中应用20余井次（储层埋深＞6000m，温度134~177℃），单井规模1000~1300m3，排量4.5~7.2m3/min，措施后最高测试产量超过200万方/天。</li>"
      },
      ]
    }

  },


      methods: {hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
img {
  object-fit: contain;
}

.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding:0 0 0 0;
      background-image: url("../../../assets/subordinate/connect2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.0597920277vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: left;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Roboto,sans-serif;
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: left;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: left;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }

      &.text-box {
        .pro3 {
          display: grid;
          grid-template-columns: repeat(5, 1fr);

          .grand2 {
            .back {
              min-height: 51vh;

              .titleDesign {
                margin: unset;
                width: unset;
                padding: 2vh 3vh 3vh;

                .title1 {
                  line-height: 1.4;
                  margin-bottom: 2vh;
                  text-align: center;
                }
              }
            }
          }
        }
      }


    }


    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Roboto,sans-serif;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 550px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/connect2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: left;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 21px;

        h4 {
          margin-bottom: 3px;
          margin-top: 5px;
        }

        p {
          margin-bottom: 2px;
          line-height: 21px;
        }
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.lr-box {
  display: flex;
  align-items: center;
  // margin-top: 3vh;
  text-align: left;

  h4 {
    font-size: 1.2vw;
    margin-bottom: 3vh;
  }

  p {
    margin-bottom: .5vh;
    font-size: 1vw;
    line-height: 1.5;
  }

  &>div {
    flex: 1;
  }

  &>div:first-child {
    width: 50%;
    margin-right: 3vw;
  }
}
</style>
