<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTs GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">ANTON Intelligent Micro-seismic Technology
              </div>
              <div class="title2">
                This is an intelligent three-component node seismograph specifically developed for real-time surface
                monitoring in oil and gas fracturing.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs ')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenmake0" style="background-color: #fff;">
            <div class="heighLight">Technical Principle</div>
            <div class="heightLight2" style="height: auto;">
              <li>This is an intelligent three-component node seismograph specifically developed for real-time surface
                monitoring in oil and gas fracturing. The product's advantages include high sensitivity in three
                components, 4G/5G remote real-time transmission, and the ability to perform ultra-long continuous field
                operations for up to 150 days with an external battery pack. When connected to a solar panel, it can
                achieve truly all-weather, real-time intelligent monitoring of micro-seismic events and advanced seismic
                source mechanism interpretation for fracture imaging;</li>
              <li>This product is widely used for real-time micro-seismic monitoring in shale gas, shale oil, tight gas,
                coalbed methane, CO<sub>2</sub> storage and migration, gas storage, and oil storage. It is also used for real-time
                geological disaster monitoring in oil pipeline areas, as well as by research institutions and major
                petroleum and geological universities;</li>
            </div>
          </div>

          <div class="screenGpts" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Technical Characteristics
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw;width: 60%">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px;height: 100%;"
                      src="@/assets/subordinate/microseismic1.png" />
                  </div>

                  <div class="titleDesign">
                    <div class="title2" style="line-height: 4vh;">
                      <li>Real-time Data Transmission: Through 4G or 5G real-time data transmission, true real-time
                        monitoring of data quality during fracturing is achieved. Supervisors no longer need to be
                        on-site and can remotely control the project progress at any time;</li>
                      <li>Intelligent Remote Sleep Control: With ultra-low power consumption, the intelligent remote
                        sleep control function can meet the real-time monitoring needs of fracturing for 25-30 days.
                        When connected to an external battery, it can provide up to 150 days of extended standby;</li>
                      <li>Anti-theft and Tracking: Equipped with intelligent anti-theft alarms and movement tracking
                        features, it prevents economic losses caused by equipment theft;</li>
                      <li>Simple System Design: The system is small, lightweight, and all modules and functions are
                        integrated into a single data acquisition station. There are no unnecessary components, and the
                        status of the equipment can be viewed and operated via mobile phones, tablets, or computers.
                        This setup increases deployment efficiency by at least 300% and reduces costs by at least 50%;
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="screenmake0" style="background-color: #fff;">
            <div class="heighLight">Software Advantages</div>
            <div class="heightLight2" style="height: auto;">
              <li>High Accuracy: When the surface micro-seismic monitoring observation system is deployed correctly, the
                horizontal positioning error of micro-seismic events (X, Y) is ≤5m, and the vertical positioning error
                (Z) is ≤40m;</li>
              <li>Real-time Data Processing: During fracturing operations, the collected micro-seismic data is processed
                in real-time to identify and locate micro-seismic events generated during the fracturing process.
                On-site, basic interpretation of the processed micro-seismic data is performed to describe fracture
                length, height, width, orientation, and location as fracturing progresses. The data is also presented in
                3D display diagrams and video formats, showing the spatial distribution of micro-seismic events over
                time. The spatial distribution of fractures around the well is interpreted to analyze the fracture
                propagation patterns and extension characteristics;</li>
              <li>DFN Modeling: Using the located micro-seismic event points, Discrete Fracture Network (DFN) modeling
                is conducted. Based on the DFN model, hydraulic fracture permeability is inversed to evaluate the
                fracture conductivity of the near-wellbore reservoir after fracturing, with a permeability inversion
                accuracy of 0.01 mD;</li>
              <li>Seismic Wave Analysis: The S/P spectral ratio is calculated based on the P-wave and S-wave arrival
                times of micro-seismic events. The distribution of tensile/wet events (inflow fractures) and shear/dry
                events (non-inflow fractures) is distinguished using the S/P spectral ratio and S-wave corner frequency
                distribution. The distribution range of inflow fractures is used to calculate the Effective Stimulated
                Reservoir Volume (ESRV);</li>
            </div>
          </div>
        <div>
<!--          <div class="screenmake4">-->
<!--            <div class="heighLight">Successful Cases</div>-->
<!--            <div class="grid">-->
<!--              <el-carousel indicator-position="outside" arrow="always" interval="5000">-->
<!--                <el-carousel-item v-for="item in success" :key="item">-->
<!--                  <div class="card">-->
<!--                    <div class="back">-->

<!--                      <div style="background-color: white;width: 55%">-->
<!--                        <div class="imgDesign">-->
<!--                          <img loading="lazy" :src="item.img" />-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="titleDesign">-->
<!--                        <div class="title1" v-html="item.title2"></div>-->
<!--                        <div class="title1" v-html="item.title1"></div>-->
<!--                        <div class="title2" v-html="item.title3"></div>-->
<!--                      </div>-->
<!--                    </div>-->


<!--                  </div>-->


<!--                </el-carousel-item>-->
<!--              </el-carousel>-->
<!--            </div>-->

<!--          </div>-->
        </div>

            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
             <netFeedback  :dialogVisible="dialogNet"
                      @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTs GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">ANTON Intelligent Micro-seismic Technology</div>
              <div class="title2">This is an intelligent three-component node seismograph specifically developed for
                real-time surface monitoring in oil and gas fracturing. </div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs ')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!-- Technical Principle -->
          <div class="screenmake0">
            <div class="heighLight">Technical Principle
            </div>
            <div class="heightLight2">
              <li>This is an intelligent three-component node seismograph specifically developed for real-time surface
                monitoring in oil and gas fracturing. The product's advantages include high sensitivity in three
                components, 4G/5G remote real-time transmission, and the ability to perform ultra-long continuous field
                operations for up to 150 days with an external battery pack. When connected to a solar panel, it can
                achieve truly all-weather, real-time intelligent monitoring of micro-seismic events and advanced seismic
                source mechanism interpretation for fracture imaging;</li>
              <li>This product is widely used for real-time micro-seismic monitoring in shale gas, shale oil, tight gas,
                coalbed methane, CO<sub>2</sub> storage and migration, gas storage, and oil storage. It is also used for real-time
                geological disaster monitoring in oil pipeline areas, as well as by research institutions and major
                petroleum and geological universities;</li>
            </div>
          </div>
          <!--  Technical Characteristics -->
          <div class="screenmake0" style="background: transparent;">
            <div class="heighLight"> Technical Characteristics
            </div>
            <div class="heightLight2">
              <li>Real-time Data Transmission: Through 4G or 5G real-time data transmission, true real-time monitoring
                of data quality during fracturing is achieved. Supervisors no longer need to be on-site and can remotely
                control the project progress at any time;</li>
              <li>Intelligent Remote Sleep Control: With ultra-low power consumption, the intelligent remote sleep
                control function can meet the real-time monitoring needs of fracturing for 25-30 days. When connected to
                an external battery, it can provide up to 150 days of extended standby;</li>
              <li>Anti-theft and Tracking: Equipped with intelligent anti-theft alarms and movement tracking features,
                it prevents economic losses caused by equipment theft;</li>
              <li>Simple System Design: The system is small, lightweight, and all modules and functions are integrated
                into a single data acquisition station. There are no unnecessary components, and the status of the
                equipment can be viewed and operated via mobile phones, tablets, or computers. This setup increases
                deployment efficiency by at least 300% and reduces costs by at least 50%;</li>

            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;" loading="lazy" src="@/assets/subordinate/microseismic1.png" />
            </div>
          </div>
          <!-- Software Advantages -->
          <div class="screenmake0">
            <div class="heighLight">Software Advantages
            </div>
            <div class="heightLight2">
              <li>High Accuracy: When the surface micro-seismic monitoring observation system is deployed correctly, the
                horizontal positioning error of micro-seismic events (X, Y) is ≤5m, and the vertical positioning error
                (Z) is ≤40m;</li>
              <li>Real-time Data Processing: During fracturing operations, the collected micro-seismic data is processed
                in real-time to identify and locate micro-seismic events generated during the fracturing process.
                On-site, basic interpretation of the processed micro-seismic data is performed to describe fracture
                length, height, width, orientation, and location as fracturing progresses. The data is also presented in
                3D display diagrams and video formats, showing the spatial distribution of micro-seismic events over
                time. The spatial distribution of fractures around the well is interpreted to analyze the fracture
                propagation patterns and extension characteristics;</li>
              <li>DFN Modeling: Using the located micro-seismic event points, Discrete Fracture Network (DFN) modeling
                is conducted. Based on the DFN model, hydraulic fracture permeability is inversed to evaluate the
                fracture conductivity of the near-wellbore reservoir after fracturing, with a permeability inversion
                accuracy of 0.01 mD;</li>
              <li>Seismic Wave Analysis: The S/P spectral ratio is calculated based on the P-wave and S-wave arrival
                times of micro-seismic events. The distribution of tensile/wet events (inflow fractures) and shear/dry
                events (non-inflow fractures) is distinguished using the S/P spectral ratio and S-wave corner frequency
                distribution. The distribution range of inflow fractures is used to calculate the Effective Stimulated
                Reservoir Volume (ESRV);</li>
            </div>
          </div>
          <!-- Successful Cases -->
          <div>
<!--            <div class="screenmake4">-->
<!--              <div class="heighLight">Successful Cases</div>-->
<!--              <div class="grid">-->
<!--                <el-carousel indicator-position="outside" arrow="always" interval="5000">-->
<!--                  <el-carousel-item v-for="item in success" :key="item">-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->

<!--                        <div style="background-color: white;width: 100%">-->
<!--                          <div class="imgDesign">-->
<!--                            <img loading="lazy" :src="item.img" />-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1" v-html="item.title2"></div>-->
<!--                          <div class="title1" v-html="item.title1"></div>-->
<!--                          <div class="title2" v-html="item.title3"></div>-->
<!--                        </div>-->
<!--                      </div>-->


<!--                    </div>-->


<!--                  </el-carousel-item>-->
<!--                </el-carousel>-->
<!--              </div>-->

<!--            </div>-->
          </div>

          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {  commentFeedback,
    productFeedback,
    netFeedback, PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: '',
      pageTitle: 'ANTON Intelligent Micro-seismic Technology-油气增产技术GPTs-Geological Engineering GPTs GPT',
      pageDescription: 'This is an intelligent three-component node seismograph specifically developed for real-time surface monitoring in oil and gas fracturing. ',
      pageKeywords: 'ANTON Intelligent Micro-seismic Technology,油气增产技术GPTs,Geological Engineering GPTs GPT',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/subordinate/microseismic2.png"),
        title3: "" +
          "<li>6 horizontal wells with 76 stages</li>"
      },
      ]
    }

  },


      methods: {hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-Geological Engineering GPTs GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,Geological Engineering GPTs GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
img {
  object-fit: contain;
}

.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding:0 0 0 0;
      background-image: url("../../../assets/subordinate/monitoring2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.4));
      }

        .topback{
    position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh  0;
      background-color: rgb(52, 48, 48,0.3);
  }.layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.0597920277vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: left;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns: minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Roboto,sans-serif;
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: left;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 5vh 3vw;
            width: 50%;
            float: right;
            text-align: left;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 68vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 100%;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;

              .title1 {
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }

              .title2 {
                line-height: 3.5vh;
                font-size: 0.9vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 70vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }

    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Roboto,sans-serif;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/monitoring2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: left;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 21px;

        h4 {
          margin-bottom: 3px;
          margin-top: 5px;
        }

        p {
          margin-bottom: 2px;
          line-height: 21px;
        }
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 20px 0;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;
        color: #fff;
      }

      .grid {
        margin-top: 25px;
        position: relative;

        .card {
          width: 80%;
          margin: 0 auto;
          height: fit-content;
          opacity: 1;
          background: #19202e;
          border-radius: 9px;

          .back {
            border-radius: 9px;
            display: flex;
            flex-direction: column;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:300px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 150px;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: 10px auto;
              color: white;
              // width: 35%;
              // float: right;

              // text-align: left;
              .title1 {
                line-height: 21px;
                font-size: 14px;
                font-weight: bold;
              }

              .title2 {
                line-height: 21px;
                font-size: 14px;
                padding: 0 10px;

                ::v-deep li {
                  margin-top: 1px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 300px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0 -15px;
          //cursor: pointer;

          border-radius: 50%;
          width: 35px;
          height: 35px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}
</style>
