<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
         <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <div class="layer2">
              <div class="title1">Intelligent analysis of peservoir geological data</div>
              <div class="title2">Integrate regional geological information of Anton global engineering, unify rules of
                data volume in each region, form geological data assets of Anton integrated reservoir, and realize
                geological information management of platform-level reservoir. With the help of machine learning, deep
                learning, large language model and other technologies, intelligent processing and analysis of massive
                reservoir geological data, accurate, detailed and interactive visualization of reservoir geological
                conditions.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs ')"> Product Inquiry</div>
            </div>
            <div class="layer3"></div>
          </div>
          <div ref="consultMove" class="screenGpts" style="background-color: white;padding-top: 0">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <div class="title">&nbsp;Technical advantages
                </div>
              </div>
            </div>
            <div class="pro3">
              <!--油田-->
              <div class="grand">
                <div class="back ">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/datainner1.png"
                         style="border-top-left-radius: 9px;border-top-right-radius: 9px"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Magnanimous data storage

                    </div>
                    <div class="title2">
                      Store rich and diverse geological data in cloud servers to ensure data security and easy access,
                      provide a secure data environment and rich original materials, and provide accurate and timely
                      data support through efficient data indexing and retrieval mechanisms.
                    </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/datainner2.png"
                         style="border-top-left-radius: 9px;border-top-right-radius: 9px"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Deep information insight

                    </div>
                    <div class="title2">Using natural language processing (NLP) technology, quickly obtain accurate
                      domain-specific information by asking questions.
                    </div>
                  </div>
                </div>
                <div class="back ">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/datainner3.png"
                         style="border-top-left-radius: 9px;border-top-right-radius: 9px"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Intelligent decision empowerment
                    </div>
                    <div class="title2">
                      Through data mining and pattern recognition, the system can provide highly accurate decision support for experts to formulate strategies for exploration, development, intelligent early warning, etc. AI knowledge base is not static, it can dynamically update its knowledge structure and prediction ability with the introduction of new data and iteration of model training.     </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div ref="consultMove" class="screennew" style="background-color: white;padding-top: 0">
            <div class="title1">
              GPT Application for Oil & Gas Development, providing quality services to the entire industry
            </div>
            <div class="title2">
              Oil & Gas Field Development Research Institute + GPT application empowers the oil and gas industry
            </div>
          </div>
          <div ref="consultMove" class="screenMake4" style="background-color: white;padding-top: 0">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand">
                <div class="back " @click="realtime">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/datainner4.jpg"
                         style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Fiber optic wellbore leakage point real-time prediction platform

                    </div>
                    <div class="title2">
                      Fiber optic wellbore leakage prediction software integrates advanced sensing technology and deep learning algorithms to provide an efficient and accurate real-time monitoring and early warning solution for the oil and gas industry to ensure wellbore safety and reduce maintenance costs.   </div>
                  </div>
                </div>
                <!-- @click="recognitionlayer" -->
                <div class="back ">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/datainner5.jpg"
                         style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Reservoir / Lithology Identification</div>
                    <div class="title2">
                      Reservoir lithology is the main characteristic that describes the mineral composition of the reservoir, reflecting the storage performance and reservoir characteristics of the rock formation. Commonly used parameters include reservoir petrophysical structure, distribution range, reservoir top interface structural form, reservoir thickness, etc. . Comprehensive reservoir characteristics can be used to predict oil and gas enrichment areas, that is, sweet spots.   </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip><netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
           <div class="topback">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTs GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Intelligent analysis of peservoir geological data</div>
              <div class="title2">
                Integrate regional geological information of Anton global engineering, unify rules of
                data volume in each region, form geological data assets of Anton integrated reservoir, and realize
                geological information management of platform-level reservoir. With the help of machine learning, deep
                learning, large language model and other technologies, intelligent processing and analysis of massive
                reservoir geological data, accurate, detailed and interactive visualization of reservoir geological
                conditions.
              </div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs ')">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <div class="title">&nbsp;Technical advantages
                </div>
              </div>
            </div>
            <div class="pro3">
              <!--油田-->
              <div class="grand">
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/datainner1.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Magnanimous data storage
                    </div>
                    <div class="title2">
                      Store rich and diverse geological data in cloud servers to ensure data security and easy access,
                      provide a secure data environment and rich original materials, and provide accurate and timely
                      data support through efficient data indexing and retrieval mechanisms.</div>
                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/datainner2.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Deep information insight
                    </div>
                    <div class="title2">Using natural language processing (NLP) technology, quickly obtain accurate domain-specific information by asking questions.</div>
                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/datainner3.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Intelligent decision empowerment</div>
                    <div class="title2">Through data mining and pattern recognition, the system can provide highly accurate decision support for experts to formulate strategies for exploration, development, intelligent early warning, etc. AI knowledge base is not static, it can dynamically update its knowledge structure and prediction ability with the introduction of new data and iteration of model training. </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screennew"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div class="title1">
              GPT Application for Oil & Gas Development, providing quality services to the entire industry
            </div>
            <div class="title2">
              Oil & Gas Field Development Research Institute + GPT application empowers the oil and gas industry
            </div>
          </div>
          <div class="screenMake4"  style="background-color: white;padding-top: 0"  ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:5vh  auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand">
                <div class="back " @click="realtime">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/datainner4.jpg"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Fiber optic wellbore leakage point real-time prediction platform

                    </div>
                    <div class="title2">
                      Fiber optic wellbore leakage prediction software integrates advanced sensing technology and deep learning algorithms to provide an efficient and accurate real-time monitoring and early warning solution for the oil and gas industry to ensure wellbore safety and reduce maintenance costs.</div>
                  </div>
                </div>
                <!--  @click="recognitionlayer" -->
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/datainner5.jpg"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Reservoir / Lithology Identification
                    </div>
                    <div class="title2">
                      Reservoir lithology is the main characteristic that describes the mineral composition of the reservoir, reflecting the storage performance and reservoir characteristics of the rock formation. Commonly used parameters include reservoir petrophysical structure, distribution range, reservoir top interface structural form, reservoir thickness, etc. . Comprehensive reservoir characteristics can be used to predict oil and gas enrichment areas, that is, sweet spots.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
              <bottomTip  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import recognition from "@/views/new/recognition.vue";

export default {
  name: "",
  computed: {
    recognition() {
      return recognition
    }
  },
  props: [],
  components: {  commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip, bottomTip},

  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: '',
      pageTitle: 'Intelligent analysis of peservoir geological data-油气藏综合地质评价服务-Geological Engineering GPTs GPT',
      pageDescription: 'Integrate regional geological information of Anton global engineering, unify rules of data volume in each region, form geological data assets of Anton integrated reservoir, and realize geological information management of platform-level reservoir. With the help of machine learning, deep learning, large language model and other technologies, intelligent processing and analysis of massive reservoir geological data, accurate, detailed and interactive visualization of reservoir geological conditions.',
      pageKeywords: 'Intelligent analysis of peservoir geological data,油气藏综合地质评价服务,Geological Engineering GPTs GPT',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/modeling4.png"),

        title3: "" +
            "<li>四川盆地CN地区煤层气地质工程一体化开发方案</li>" +
            "<li>金山气田地质工程一体化风险总包实施方案\t</li>" +
            "<li>长宁煤层气地质工程一体化开发方案</li>" +
            "<li>贵州六枝牛场区块煤矿瓦斯综合治理——地质工程一体化总包服务</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/fibernew")
    },
    recognitionlayer() {
      window.open("/recognition")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Intelligent analysis of peservoir geological data-油气藏综合地质评价服务-Geological Engineering GPTs GPT',
        pageDescription: 'Integrate regional geological information of Anton global engineering, unify rules of data volume in each region, form geological data assets of Anton integrated reservoir, and realize geological information management of platform-level reservoir. With the help of machine learning, deep learning, large language model and other technologies, intelligent processing and analysis of massive reservoir geological data, accurate, detailed and interactive visualization of reservoir geological conditions.',
        pageKeywords: 'Intelligent analysis of peservoir geological data,油气藏综合地质评价服务,Geological Engineering GPTs GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 0 0 0 0;
      background-image: url("../../assets/datainnerbackground.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

        .topback{
          position: fixed;
          z-index: 9;
          width: 99vw;
          padding: 1vh  0;
          background-color: rgb(52, 48, 48,0.3);
  }
      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }

        .title2 {
          margin-top: 3vh;
          width: 40vw;
          height: fit-content;

          font-size: 1.0597920277vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: black;
          line-height: 4vh;
        }
        .titlle3{
          margin-top: 3vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;

        .grand {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
          row-gap: 5vh;
          column-gap: 1vw;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin: 0 auto;
            height: 35vh;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: center;
            font-size: 1vw;
            font-family: Roboto,sans-serif;
            font-weight: bold;
            //color: #2168DB;

            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            margin: 1vh auto;;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            padding: 2vh 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Roboto,sans-serif;
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }


    }

    .screennew {
      background-image: url("../../assets/datainner.webp");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      height: 40vh;
      position: relative;
      text-align: center;

      .title1 {
        position: absolute;
        color: white;
        margin: 10vh 5vw;
        text-align: center;
        width: fit-content;

        font-size: 2vw;
        font-weight: bold;
      }

      .title2 {
        position: absolute;
        color: white;
        margin: 22vh 8vw;
        text-align: center;
        width: fit-content;

        font-size: 1.8vw;
      }
    }

    .screenMake4 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;

        .grand {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          row-gap: 5vh;
          column-gap: 1vw;

          .back {
            transition: transform 0.3s ease-out;
            width: 100%;
            position: relative;
            height: 30vh;
            background: #bfd8f9;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;

            .photo {
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              background-color: transparent; /* 这里设置背景颜色为透明 */
              opacity: 1.25;
              width: fit-content;
              margin: auto 0;
              height: 100%;
            }


            .title1 {
              padding: 0;
              opacity: 4;
              text-align: center;
              font-size: 0.9vw;
              font-family: Roboto,sans-serif;
              font-weight: bold;
              line-height: 3vh;
            }

            .title2 {
              opacity: 4;
              //text-align: justify;
              margin: 1vh auto;;
              justify-content: center;
              align-items: center;
              font-size: 0.8vw;
              font-family: Roboto,sans-serif;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .titleDesign {
              padding: 1vh 1vw;
              float: right;
              width: 30vw;
              text-align: justify;
            }
          }
        }

        .back:hover {
          transform: translateY(-10px);
        }
      }
    }
  }
}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 500px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../assets/groundinnerbannerM.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #fff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #fff;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        font-weight: 700;
        line-height: 26px;

      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenGpts {

//left: 10.86077411900635vw;
width: 100vw;
height: fit-content;
padding: 20px 4.6875vw;
background: white;

.title {
  text-align: center;
  height: 21px;
  opacity: 1;
  font-size: 20px;
  //color: #53A3F7;
  font-family: Roboto,sans-serif;
  font-weight: 700;
  line-height: 26px;

}

.heightLight2 {
  margin: 19px 2vw;

  opacity: 4;
  text-align: justify;

  font-size:14px;
  font-family: Roboto,sans-serif;
  font-weight: 500;
  //color: #2168DB;
  line-height: 22px;
}



.pro3 {
  //margin-top: -4vh;
  position: relative;
  height: fit-content;
  margin-top: 25px;

  grid-template-columns:minmax(0, 1fr);
  row-gap:15px;
  .grand {
    //cursor: pointer;
    width: 100%;
    height:fit-content;
    opacity: 1;
    display: grid;
    grid-template-columns: minmax(0,1fr) ;
    row-gap: 20px;

    .back {
      transition: transform 0.5s ease;
      width: 100%;
      position: relative;
      height: 100%;
      background: #bfd8f9;
      box-shadow: 5px 0 14px 0px #D4E4FF;
      border: 2px solid #FFFFFF;
      border-radius: 9px;
      //display: flex;
    }
    .photo {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent; /* 这里设置背景颜色为透明 */
      opacity: 1.25;
      width: 100%;
      margin:0 auto;
      height: 200px;
    }


    .title1 {
      padding: 0;
      opacity: 4;
      text-align: center;
      font-size:16px;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      //color: #2168DB;

      line-height:22px;
    }

    .title2 {
      opacity: 4;
      text-align: justify;
      margin:1vh auto;;
      justify-content: center;
      align-items: center;
      font-size:14px;
      font-family: Roboto,sans-serif;
      font-weight: 500;
      //color: #2168DB;
      line-height: 22px;
    }
    .titleDesign{
      padding: 15px;
      float: right;
      text-align: justify;
    }

    .consult {
      position: absolute;
      display: none;
      font-family: Roboto,sans-serif;
      font-weight: 500;
      font-size:0.9vw;
      bottom: 3.94437577255871vh;
      left: 1.85540150202195vw;
    }
  }
}



}
.screennew{
background-image: url("../../assets/datainner.webp");
background-position: center;
background-size: cover;
background-repeat: no-repeat;
background-color: transparent;
height: fit-content;
position: relative;

.title1{
  color: white;
  padding: 35px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.title2{
  margin-top: 25px;
  padding-bottom:35px ;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
}
.screenMake4 {

//left: 10.86077411900635vw;
width: 100vw;
height: fit-content;
padding: 20px 4.6875vw;
background: white;

.title {
  text-align: center;
  height: 21px;
  opacity: 1;
  font-size: 20px;
  //color: #53A3F7;
  font-family: Roboto,sans-serif;
  font-weight: 700;
  line-height: 26px;

}

.light {
  margin: 19px 2vw;

  opacity: 4;
  text-align: justify;

  font-size:14px;
  font-family: Roboto,sans-serif;
  font-weight: 500;
  //color: #2168DB;
  line-height: 22px;
}



.pro3 {
  //margin-top: -4vh;
  height: fit-content;
  width: 100%;
  z-index: 89156;

  grid-template-columns:minmax(0, 1fr);
  .grand {
    cursor: pointer;
    width: 100%;
    height:fit-content;
    opacity: 1;
    display: grid;
    grid-template-columns: minmax(0,1fr) ;
    row-gap: 15px;
    .back {
      transition:transform 0.3s ease-out;
      width: 100%;
      position: relative;
      height: 100%;
      background: #bfd8f9;
      box-shadow: 5px 0 14px 0px #D4E4FF;
      border: 2px solid #FFFFFF;
      border-radius: 9px;
      .photo {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: white; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        margin:auto 0;
        height:auto;
      }


      .title1 {
        padding: 0;
        opacity: 4;
        text-align: center;
        font-size:16px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        line-height: 22px;
      }

      .title2 {
        opacity: 4;
        text-align: justify;
        margin:15px auto;;
        justify-content: center;
        align-items: center;
        font-size:14px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        line-height: 22px;
      }
      .titleDesign{
        padding: 15px ;
        text-align: justify;
      }
    }
  }
  .back:hover {
    transform: translateY(-10px);
  }
}
}
  }

}
</style>
